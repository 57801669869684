import { createSlice } from '@reduxjs/toolkit';

const blueprintReducerSlice = createSlice({
    name: 'blueprint',
    initialState: {
        fileName:null,
        blueprintSrc: null,
        enableUpload:false,
    },
    reducers: {
        setBlueprint: (state, action) => {
            state.fileName = action.payload.fileName || null;
            state.blueprintSrc = action.payload.src || null;
            state.enableUpload = !!action.payload.src;
        },
        setEnableUploadBlueprint: (state, action) => {
            state.enableUpload = action.payload || false;
        }
    },
});

export const { setBlueprint, setEnableUploadBlueprint } = blueprintReducerSlice.actions;
export default blueprintReducerSlice.reducer;