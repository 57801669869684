import { createSlice } from '@reduxjs/toolkit';

const userUploadsSlice = createSlice({
    name: 'userUploads',
    initialState: {
        userUploads: [], // Initial activePanelId state is 1
    },
    reducers: {
        setUserUploads: (state, action) => {
            const project = action.payload;
            state.userUploads = [project,...state.userUploads];
        }
    },
});

export const { setUserUploads } = userUploadsSlice.actions;
export default userUploadsSlice.reducer;