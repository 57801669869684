import { createSlice } from '@reduxjs/toolkit';

const myAlertReducerSlice = createSlice({
    name: 'my-alert',
    initialState: {
        show: false,
        message: '',
        type:'success',
        duration:2000,
        customClasses:'',
    },
    reducers: {
        setMyAlert: (state, action) => {
            state.show = action.payload.show;
            state.message = action.payload.message;
            state.type = action.payload?.type || "success";
            state.duration = action.payload?.duration || state.duration;
            state.customClasses = action.payload?.customClasses || state.customClasses;
        }
    },
});

export const { setMyAlert } = myAlertReducerSlice.actions;
export default myAlertReducerSlice.reducer;