import {createSlice} from "@reduxjs/toolkit";


const activeFenceToolSlice = createSlice({
    name:'fenceTool',
    initialState:{
        drawFenceToolActive:false,
        activeFenceToolType:null,

    },
    reducers:{
        setDrawFenceActive:(state, action)=>{
            state.drawFenceToolActive = action.payload
        },
        setActiveFenceTool:(state, action)=>{
            state.activeFenceToolType = action.payload
        },
    },
});

export const { setActiveFenceTool,setDrawFenceActive } = activeFenceToolSlice.actions;
export default activeFenceToolSlice.reducer;
