import { createSlice } from '@reduxjs/toolkit';
import {panelKeys} from "../../utils/constants/constants";

const reducerSlice = createSlice({
    name: 'activePanelId',
    initialState: {
        activePanelId: panelKeys.CANVAS, // Initial activePanelId state is 1
    },
    reducers: {
        setActivePanelId: (state, action) => {
            state.activePanelId = action.payload;
        }
    },
});

export const { setActivePanelId } = reducerSlice.actions;
export default reducerSlice.reducer;