import {configureStore} from "@reduxjs/toolkit";
import canvasReducer from "./reducers/canvasReducer"
import panelReducer from "./reducers/panelReducer";
import fenceToolReducer from "./reducers/fenceToolReducer";
import savedProjectsReducer from "./reducers/savedProjectsReducer";
import blueprintReducer from "./reducers/blueprintReducer";
import myAlertReducer from "./reducers/myAlertReducer";
import drawingCanvasTypeReducer from "./reducers/drawingCanvasTypeReducer";
import canvasActiveObject from "./reducers/activeObjectReducer";
import userUploadsReducer from "./reducers/userUploadsReducer";
import pageMeasurementsReducer from "./reducers/pageMeasurementsReducer";
import fenceDataReducer from "./reducers/fenceDataReducer";
import fenceSettingReducerSlice from "./reducers/fence/fenceSettingReducer"
import projectDetailReducerSlice from "./reducers/projectDetailReducer"

const store = configureStore({
    reducer:{
        canvas:canvasReducer,
        canvasActiveObject:canvasActiveObject,
        panel:panelReducer,
        fenceTool: fenceToolReducer,
        projects:savedProjectsReducer,
        userUploads:userUploadsReducer,
        myAlert:myAlertReducer,
        blueprint:blueprintReducer,
        drawingCanvasType:drawingCanvasTypeReducer,
        pageMeasurements:pageMeasurementsReducer,
        fenceData:fenceDataReducer,
        fenceSetting:fenceSettingReducerSlice,
        projectDetail:projectDetailReducerSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;