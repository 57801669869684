import {createSlice} from "@reduxjs/toolkit";


const fenceSettingReducerSlice = createSlice({
    name:'fenceSetting',
    initialState:{
        globalSetting:null,
    },
    reducers:{
        setFenceSetting:(state, action)=>{
            state.globalSetting = action.payload
        },
    },
});

export const { setFenceSetting } = fenceSettingReducerSlice.actions;
export default fenceSettingReducerSlice.reducer;
