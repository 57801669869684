import { createSlice } from '@reduxjs/toolkit';
import {feetInOneMeter, measureTypes, measureUnitTypes} from "../../utils/constants/constants";

const pageMeasurementsSlice = createSlice({
    name: 'pageMeasurements',
    initialState: {
        setBy:'default',
        isSetup:false,
        scaleUnitType: measureTypes.Imperial,
        scaleUnit: measureUnitTypes.Imperial,
        pixelsPerFoot: 1,
        pixelsPerMeter: feetInOneMeter,
        enable:false
    },
    reducers: {
        setPageMeasurements: (state, action) => {
            state.pixelsPerFoot = action.payload.pixelsPerFoot || 1;
            state.pixelsPerMeter = action.payload.pixelsPerMeter || feetInOneMeter;
            state.setBy = action.payload.setBy || 'default';
            state.isSetup = action.payload.isSetup || false;
        },
        setPageMeasurementsUnits: (state, action) => {
            state.scaleUnitType = action.payload.scaleUnitType || measureTypes.Imperial;
            state.scaleUnit = action.payload.scaleUnit || measureUnitTypes.Imperial;
        },
        setScaleSettingEnable: (state, action) => {
            state.enable = action.payload || false
        }
    },
});

export const { setPageMeasurements, setPageMeasurementsUnits, setScaleSettingEnable } = pageMeasurementsSlice.actions;
export default pageMeasurementsSlice.reducer;