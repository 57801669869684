import { createSlice } from '@reduxjs/toolkit';

const savedProjectsSlice = createSlice({
    name: 'savedProjects',
    initialState: {
        savedProjects: [], // Initial activePanelId state is 1
    },
    reducers: {
        setSavedProjects: (state, action) => {
            const project = action.payload;
            const projects = [project,...state.savedProjects]
            state.savedProjects = projects;
        }
    },
});

export const { setSavedProjects } = savedProjectsSlice.actions;
export default savedProjectsSlice.reducer;