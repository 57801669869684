import { createSlice } from '@reduxjs/toolkit';

const canvasSlice = createSlice({
    name: 'canvasActiveObject',
    initialState: {
        canvasActiveObject: null, // Initial canvas state is null
    },
    reducers: {
        setCanvasActiveObject: (state, action) => {
            state.canvasActiveObject = action.payload;
        }
    },
});

export const { setCanvasActiveObject } = canvasSlice.actions;
export default canvasSlice.reducer;