import { createSlice } from '@reduxjs/toolkit';

const projectDetailSlice = createSlice({
    name: 'projectDetail', // Name of the slice
    initialState: {
        projectName: '',
        projectDescription: '',
        contactInfo: {
            fullName: '',
            email: '',
            phoneNumber: '',
            jobsiteAddress: '',
        },
    },
    reducers: {
        setProjectDetails: (state, action) => {
            state.projectName = action.payload.projectName;
            state.projectDescription = action.payload.projectDescription;
            state.contactInfo = action.payload.contactInfo;
        },
        // Optionally, add more actions if needed in the future
    },
});

export const { setProjectDetails } = projectDetailSlice.actions;
export default projectDetailSlice.reducer;
