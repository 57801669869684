import { createSlice } from '@reduxjs/toolkit';

const fenceDataSlice = createSlice({
    name: 'fenceData',
    initialState: {
        fenceTypes: [],
        fenceStyles: [],
        fenceComponents: [],
        fenceDetails: null,
        selectedFenceType: '',
        selectedFenceStyle: '',
    },
    reducers: {
        setFenceTypes: (state, action) => {
            state.fenceTypes = action.payload;
        },
        setFenceStyles: (state, action) => {
            state.fenceStyles = action.payload;
        },
        // Fence components
        setFenceComponents: (state, action) => {
            state.fenceComponents = action.payload;
        },
        setFenceDetails: (state, action) => {
            state.fenceDetails = action.payload;
        },
        setSelectedFenceTypeAct: (state, action) => {
            state.selectedFenceType = action.payload;
        },
        setSelectedFenceStyleAct: (state, action) => {
            state.selectedFenceStyle = action.payload;
        },
    },
});

export const { setFenceTypes, setFenceStyles, setFenceDetails, setSelectedFenceTypeAct, setSelectedFenceStyleAct, setFenceComponents } = fenceDataSlice.actions;
export default fenceDataSlice.reducer;
