import { createSlice } from '@reduxjs/toolkit';

const canvasSlice = createSlice({
    name: 'canvas',
    initialState: {
        canvas: null, // Initial canvas state is null
    },
    reducers: {
        setCanvas: (state, action) => {
            state.canvas = action.payload;
        }
    },
});

export const { setCanvas } = canvasSlice.actions;
export default canvasSlice.reducer;