
//Cursors

import draw_fence_cur from "../../assets/cursors/drawfence.cur";
import draw_dimension_cur from "../../assets/cursors/draw_dimension.cur";
import draw_S_cantilever_gate_cur from "../../assets/cursors/draw_S_cantilever_gate.cur";
import draw_D_cantilever_gate_cur from "../../assets/cursors/draw_D_cantilever_gate.cur";
import draw_S_roll_gate_cur from "../../assets/cursors/draw_S_roll_gate.cur";
import draw_D_roll_gate_cur from "../../assets/cursors/draw_D_roll_gate.cur";
import draw_S_swing_gate_cur from "../../assets/cursors/draw_S_swing_gate.cur";
import draw_D_swing_gate_cur from "../../assets/cursors/draw_D_swing_gate.cur";

import projectIconWhite from "../../assets/icons/white/ClipboardText.svg"
import projectIconDark from "../../assets/icons/dark/ClipboardText.svg"
import canvasIconWhite from "../../assets/icons/white/DiceFour.svg"
import canvasIconDark from "../../assets/icons/dark/DiceFour.svg"
import fencesIconWhite from "../../assets/icons/white/DropboxLogo.svg"
import fencesIconDark from "../../assets/icons/dark/DropboxLogo.svg"
import elementsIconWhite from "../../assets/icons/white/CircleHalf.svg"
import elementsIconDark from "../../assets/icons/dark/CircleHalf.svg"
import textIconWhite from "../../assets/icons/white/TextT.svg"
import textIconDark from "../../assets/icons/dark/TextT.svg"
import uploadIconWhite from "../../assets/icons/white/Upload.svg"
import uploadIconDark from "../../assets/icons/dark/Upload.svg"

import googleMapIcon from "../../assets/icons/steps/google-map-icon.png"
import blueprintIcon from "../../assets/icons/steps/blueprint.png"
import blankCanvasIcon from "../../assets/icons/steps/blank-canvas.png"

export const CANVAS_TYPES = {
    google: "google",
    blueprint: "blueprint",
    blank: "blank",
};

export const mapCanvasNames = {
    google: "Google Map",
    blueprint: "Blueprint",
    blank: "Blank Canvas",
};
export const mapCanvasIcons = {
    google: googleMapIcon,
    blueprint: blueprintIcon,
    blank: blankCanvasIcon,
};

export const feetInOneMeter = 3.28084
export const measureTypes = {Metric : 'Metric',Imperial : 'Imperial'}
export const measureUnitTypes = {Metric : 'm',Imperial : 'ft'}

export const panelKeys = {
    NULL  : -1,
    PROJECT  : 3,
    CANVAS  : 1,
    FENCE  : 2,
    ELEMENTS  : 5,
    TEXT  : 4,
    UPLOADS  : 6,
    COMMON  : 8,
}
export const menuItems = {
    Project : "Project",
    Canvas : "Canvas",
    Fences : "Fences",
    Elements : "Elements",
    Text : "Text",
    Upload : "Upload",
}
export function getMenuIcon(id, active = false) {
    const icons = {
        Project: { dark: projectIconDark, white: projectIconWhite },
        Canvas: { dark: canvasIconDark, white: canvasIconWhite },
        Fences: { dark: fencesIconDark, white: fencesIconWhite },
        Elements: { dark: elementsIconDark, white: elementsIconWhite },
        Text: { dark: textIconDark, white: textIconWhite },
        Upload: { dark: uploadIconDark, white: uploadIconWhite },
    };

    return icons[id] ? (active ? icons[id].dark : icons[id].white) : null;
}


export const menuTabPanels = [
    {
        id: panelKeys.PROJECT,
        title: 'Project',
    },
    {
        id: panelKeys.CANVAS,
        title: 'Canvas',
    },
    {
        id: panelKeys.FENCE,
        title: 'Fences',
    },
    {
        id: panelKeys.ELEMENTS,
        title: 'Elements',
    },
    {
        id: panelKeys.TEXT,
        title: 'Text',
    },
    {
        id: panelKeys.UPLOADS,
        title: 'Upload',
    },
];


export const FAB_OBJECTS = {
    textElement:"textElement",
    shapeElement:"shapeElement",
    uploadElement:"uploadElement",
    fenceLine:"fenceLine",
    fenceGateLine:"fenceGateLine",
    fenceLineNode:"fenceLineNode",
    fenceGateNode:"fenceGateNode",
    fenceDoubleLine:"fenceDoubleLine",
    scaleSettingLine:"scale-setting-line",
    scaleSettingLineNode:"scale-setting-line-node"
}

export const FAB_GATE_OBJECTS = [FAB_OBJECTS.fenceGateLine,FAB_OBJECTS.fenceGateNode]


export const FENCE_GATES = {
    select:'select',
    fence:"fence",
    singleRoll:"singleRollGate",
    doubleRoll:"doubleRollGate",
    singleSwing:"singleSwingGate",
    doubleSwing:"doubleSwingGate",
    singleCantilever:"singleCantileverGate",
    doubleCantilever:"doubleCantileverGate",
}

export const FENCE_COLORS = {
    galvanized: "#a19a9a",  // Assuming a metallic color for galvanized fences
    aluminum: "#A8A8A8",    // Light grey for aluminum
    black: "#000000",       // Standard black
    white: "#FFFFFF",       // Standard white
    green: "#008000",       // A typical green
    brown: "#A52A2A",       // A common brown shade
    red: "#FF0000",         // Bright red
};

export const CUSTOM_CURSORS = {
    fence:draw_fence_cur,
    singleRollGate:draw_S_roll_gate_cur,
    doubleRollGate:draw_D_roll_gate_cur,
    singleSwingGate:draw_S_swing_gate_cur,
    doubleSwingGate:draw_D_swing_gate_cur,
    singleCantileverGate:draw_S_cantilever_gate_cur,
    doubleCantileverGate:draw_D_cantilever_gate_cur,
    draw_dimension_cur:draw_dimension_cur
}

export const fenceObjectColorByType = {
    "chainlink": "#FF0000", // Red
    "wood": "#A52A2A", // Brown
    "Wrought Iron Fence": "#A9A9A9", // Dark Grey
    "Farm / Agricultural Fence": "#008000", // Green
    "Masonry Fence": "#800080", // Purple
    "Temporary Fence": "#FFA500", // Light Orange
    "Pet / Invisible Fence": "#FF4500", // Light Red
    "Railings Pink": "#FFC0CB", // Pink
    "Pool Fence": "#ADD8E6" // Light Blue
}


export const FENCE_COMPONENT_QUANTITY_FORMULA_VARIABLES = {
    totalFenceLength:'totalFenceLength'
}

export const SEC_COMPS = [
    {
        name: 'ChainLink Mesh',
        id:'chainLinkMesh',
        isCeil:true,
        dependencies:{fenceHeight:true,fenceColor:true},
    },
    {
        name: 'Tension Wire' ,
        id:'tensionWire',
        isCeil:true,
        dependencies:{fenceColor:true}
    },
    {
        name: 'Top Rail',
        id:'topRail',
        isCeil:true,
        dependencies:{fenceColor:true},
    },
    {
        name: 'Top Rail Ties',
        id:'lineRailTies',
        isCeil:true,
        dependencies:{fenceColor:true},
    },
    {
        name: 'Barb Wire' ,
        isCeil:true,
        id:'barbWire',
    },
    {
        name: 'Hog Rings' ,
        isCeil:true,
        id:'hogRings',

    },
]

export const HOOKUPS_COMPS = [
    {
        name: 'Tension Band',
        id:'tensionBand',
        dependencies:{fenceColor:true,terminalPostDiameter:true},
    },
    {
        name: 'Brace Band',
        id:'braceBand',
        dependencies:{fenceColor:true},
    },
    {
        name: 'Rail End',
        id:'railEnd',
        dependencies:{fenceColor:true},
    },
    {
        name: 'Rail End Combo Cup',
        id:'railEndComboCup',
        dependencies:{fenceColor:true},
    },
    {
        name: 'Tension Bar',
        id:'tensionBar',
        dependencies:{fenceColor:true, fenceHeight:true},
    },
    {
        name: 'Nuts and Bolts',
        id:'nutsAndBolts',
    },
    {
        name: 'Truss Rod',
        id:'trussRod',
        dependencies:{fenceColor:true},

    },
    {
        name: 'Truss Rod Tightener',
        id:'trussRodTightener',
        dependencies:{fenceColor:true},

    },
    {
        name: 'Brace Rail',
        id:'braceRail',
        dependencies:{fenceColor:true},

    },
]

export const SEC_COMPS_IDS = {
    chainLinkMesh:'chainLinkMesh',
    tensionWire: 'tensionWire' ,
    topRail: 'topRail',
    lineRailTies: 'lineRailTies',
    barbWire: 'barbWire' ,
    hogRings: 'hogRings' ,

}

export const HOOKUP_COMPS_IDS = {
    tensionBand:'tensionBand',
    braceBand: 'braceBand' ,
    railEnd: 'railEnd',
    railEndComboCup: 'railEndComboCup',
    tensionBar: 'tensionBar' ,
    nutsAndBolts: 'nutsAndBolts' ,
    trussRod: 'trussRod' ,
    trussRodTightener: 'trussRodTightener' ,
    braceRail: 'braceRail' ,
}

export const AVAILABLE_FENCE_HEIGHTS = [
    '3', '3.5', '4', '5', '6', '7', '8', '9', '10', '12', '16', '18', '20', '21',
];

// Define a palette of colors. Ensure there are at least as many colors as there are fence heights.
const COLOR_PALETTE = [
    '#C8102E', // Vivid Red
    '#00539C', // Strong Blue
    '#007B5F', // Deep Green
    '#6B3074', // Rich Purple
    '#FF8C00', // Deep Orange
    '#A50034', // Crimson
    '#007C7A', // Dark Cyan
    '#58595B', // Dark Grey
    '#9B2D1F', // Brick Red
    '#4B5D67', // Slate Grey
    '#3C1053', // Dark Violet
    '#154734', // Hunter Green
    '#775135', // Bronze
    '#354A21', // Moss Green
];

/**
 * Function to get the color for a given fence height
 * @param {string} height - The height of the fence
 * @return {string} - The hex color code for the fence
 */
export function getColorForFenceHeight(height) {
    // Create a mapping from each fence height to a specific color
    const heightColorMap = new Map();
    AVAILABLE_FENCE_HEIGHTS.forEach((h, index) => {
        heightColorMap.set(h, COLOR_PALETTE[index % COLOR_PALETTE.length]);
    });

    return heightColorMap.get(height) || '#000000'; // Default to black if no specific color is found
}
