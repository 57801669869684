import { createSlice } from '@reduxjs/toolkit';
import {CANVAS_TYPES} from "../../utils/constants/constants";

const drawingCanvasTypeSlice = createSlice({
    name: 'drawingCanvasType',
    initialState: {
        type:CANVAS_TYPES.blank,
    },
    reducers: {
        setDrawingCanvasType: (state, action) => {
            state.type = action.payload
        }
    },
});

export const { setDrawingCanvasType } = drawingCanvasTypeSlice.actions;
export default drawingCanvasTypeSlice.reducer;